
































import truncateString from '../../../../helpers/utils/truncateString';
import { computed, defineComponent, ref, useRoute, } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'PictimeCategoryTop',
  components: {},
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    activeCategoryName: {
      type: String,
      default: '',
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    metaDescription: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const maxLengthDesktop = ref(350);
    const maxLengthMobile = ref(100);

    const metaDescriptionTruncatedDesktop = ref(truncateString(props.metaDescription, maxLengthDesktop.value, ''));

    const metaDescriptionDesktop = computed({
      get() {
        return metaDescriptionTruncatedDesktop.value;
      },
      set(newValue) {
        metaDescriptionTruncatedDesktop.value = newValue;
      }
    });

    const metaDescriptionTruncatedMobile = ref(truncateString(props.metaDescription, maxLengthMobile.value, ''));

    const metaDescriptionMobile = computed({
      get() {
        return metaDescriptionTruncatedMobile.value;
      },
      set(newValue) {
        metaDescriptionTruncatedMobile.value = newValue;
      }
    });

    const isLongDesc = (maxLength) => {
      if (!props.metaDescription) {
        return false;
      }
      return props.metaDescription.length > maxLength;
    };

    const isResearch = ref(false);
    const route = useRoute();
    const { path } = route.value;
    const currentStep = computed(() => path.split('/').pop());
    isResearch.value = currentStep.value === 'search'


    function initDescription() {
      if (process.browser) {
        const elemDesktop = document.getElementById('read-button-desktop');
        if (elemDesktop) {
          elemDesktop.textContent = 'Lire la suite';
        }
        const elemMobile = document.getElementById('read-button-mobile');
        if (elemMobile) {
          elemMobile.textContent = 'Lire la suite';
        }
      }
      metaDescriptionDesktop.value = truncateString(props.metaDescription, maxLengthDesktop.value, '');
      metaDescriptionMobile.value = truncateString(props.metaDescription, maxLengthMobile.value, '');
    }

    initDescription();

    function readDescription(device, text, maxLength) {
      if (process.browser) {
        const elem = document.getElementById('read-button-' + device);
        elem.textContent = elem.textContent === 'Lire la suite' ? 'Réduire' : 'Lire la suite';
      }
      if (device === 'desktop') {
        return metaDescriptionDesktop.value = text.length > maxLength ? truncateString(props.metaDescription, maxLengthDesktop.value, '') : props.metaDescription;
      }
      return metaDescriptionMobile.value = text.length > maxLength ? truncateString(props.metaDescription, maxLengthMobile.value, '') : props.metaDescription;
    }

    return {
      metaDescriptionDesktop,
      metaDescriptionMobile,
      maxLengthDesktop,
      maxLengthMobile,
      isLongDesc,
      readDescription,
      isResearch
    }
  },
});
