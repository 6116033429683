








import { defineComponent, ref, useContext, useFetch } from '@nuxtjs/composition-api';
import { useUiHelpers } from '~/composables';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import { Breadcrumb } from '~/modules/catalog/types';
import PictimeBreadcrumbs from '~/components/atoms/PictimeBreadcrumbs.vue';
import SvgImage from '~/components/General/SvgImage.vue'

export default defineComponent({
  components: { PictimeBreadcrumbs, SvgImage },
  setup() {
    const { getCatLink } = useUiHelpers();
    const { localePath } = useContext();

    const {
      categoryAncestors, isCategoryTreeLoaded, loadCategoryTree,
    } = useTraverseCategory();
    const breadcrumbs = ref<Breadcrumb[]>([]);

    useFetch(async () => {
      if (!isCategoryTreeLoaded.value) {
        await loadCategoryTree();
      }

      breadcrumbs.value = categoryAncestors.value.map((category) => ({
        text: category.name,
        link: localePath(getCatLink(category)),
      }));
    });

    return {
      breadcrumbs,
    };
  },
});
