<template functional>
  <nav
    :class="[data.class, data.staticClass, 'sf-breadcrumbs pictime-breadcrumbs']"
    :style="[data.style, data.staticStyle]"
    aria-label="breadcrumb"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <ol class="sf-breadcrumbs__list pictime-breadcrumbs__list">
      <li class="sf-breadcrumbs__list-item pictime-breadcrumbs__list-item">
        <SvgImage
          class="pictime-breadcrumbs__icon smartphone-only"
          height="12"
          icon="Icon_arrow_left"
          width="12"
        />
        <component
          :is="injections.components.SfLink"
          class="sf-breadcrumbs__breadcrumb pictime-breadcrumbs__breadcrumb"
          link="/"
        >
          Accueil
        </component>
      </li>
      <li
        v-for="(breadcrumb, i) in props.breadcrumbs"
        :key="i"
        :aria-current="
        $options.breadcrumbLast(props.breadcrumbs) === i && 'page'
      "
        class="sf-breadcrumbs__list-item pictime-breadcrumbs__list-item"
      >
        <template v-if="$options.breadcrumbLast(props.breadcrumbs) !== i">
          <slot name="link" v-bind="{ breadcrumb }">
            <SvgImage
              class="pictime-breadcrumbs__icon smartphone-only"
              height="12"
              icon="Icon_arrow_left"
              width="12"
            />
            <component
              :is="injections.components.SfLink"
              :data-testid="breadcrumb.text"
              :link="breadcrumb.link"
              class="sf-breadcrumbs__breadcrumb pictime-breadcrumbs__breadcrumb"
            >
              {{ breadcrumb.text }}
            </component>
          </slot>
        </template>
        <template v-else>
          <slot name="current" v-bind="{ breadcrumb }">
            <component
              :is="injections.components.SfLink"
              :link="breadcrumb.link"
              class="sf-breadcrumbs__breadcrumb pictime-breadcrumbs__breadcrumb current"
            >
              {{ breadcrumb.text }}
            </component>
          </slot>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
import { SfLink } from "@storefront-ui/vue";
import SvgImage from '~/components/General/SvgImage.vue';

export default {
  name: "PictimeBreadcrumbs",
  inject: {
    components: {
      default: {
        SfLink,
        SvgImage,
      },
    },
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  breadcrumbLast(breadcrumbs) {
    return breadcrumbs.length - 1;
  },
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/atoms/SfBreadcrumbs.scss";
@import "@/assets/theme/default/scss/components/atoms/pictime-breadcrumbs.scss";
</style>
