<template>
  <div>
    <slot name="category">
      <div class="desktop-only sf-category-sidebar">
        <p v-if="hasParentCategory" class="sf-category-sidebar__header">{{ activeCategory.name }}</p>
        <SfList v-if="activeCategory && activeCategory.children" :class="{'list__subcategory': hasParentCategory}" class="list">
          <SfListItem
            v-for="(subCat, j) in activeCategory.children"
            :key="j"
            class="list__item"
          >
            <div>
              <nuxt-link
                :class="subCat.isCurrent ? 'sidebar--cat-selected' : ''"
                :to="localePath(th.getCatLink(subCat))"
                rel="nofollow"
              >
                {{ subCat.name }}
              </nuxt-link>
            </div>
          </SfListItem>
        </SfList>
      </div>
      <PictimeCarousel
        v-if="activeCategory && activeCategory.children"
        class="carousel-category smartphone-only"
      >
        <PictimeCarouselItem
          v-for="(subCat, j) in activeCategory.children"
          :key="j"
          class="carousel-category__item"
        >
          <SfMenuItem
            :label="subCat.name"
            class="menu__item"
          >
            <template #label="{ label }">
              <nuxt-link
                :class="subCat.isCurrent ? 'sidebar--cat-selected' : ''"
                :to="localePath(th.getCatLink(subCat))"
                rel="nofollow"
              >
                {{ label }}
              </nuxt-link>
            </template>
          </SfMenuItem>
        </PictimeCarouselItem>
      </PictimeCarousel>
    </slot>
  </div>
</template>

<script>
import { SfDivider, SfList, SfMenuItem, } from '@storefront-ui/vue';
import { computed, defineComponent, } from '@nuxtjs/composition-api';
import { useUiHelpers } from '~/composables';
import PictimeCarousel from "../../../../../components/organisms/PictimeCarousel";

export default defineComponent({
  name: 'CategorySidebarMenu',
  components: {
    SfList,
    SfMenuItem,
    PictimeCarousel,
    SfDivider,
  },
  props: {
    resolveUrl: Boolean,
    noFetch: Boolean,
    activeCategory: {
      type: Object,
      default: {},
    }
  },
  setup(props) {
    const th = useUiHelpers();

    const hasParentCategory = computed(() => props.activeCategory && props.activeCategory.level > 3);

    return {
      th,
      hasParentCategory
    };
  },
});
</script>

<style lang="scss" scoped>
.sf-divider {
  margin-bottom: var(--spacer-xs);
}

.sf-list {
  .sf-list__item.list {
    --menu-item-font-size: var(--font-size--sm);

    &__subcategory {
      margin-left: var(--spacer-sm);
    }

    &__item {
      padding: var(--spacer-xs) 0 0;
      font-family: var(--font-family--primary) !important;
      font-size: 14px !important;

      ::v-deep .sf-menu-item {
        font-family: var(--font-family--primary) !important;
        font-size: var(--cromo-font-size--body);
        font-weight: var(--font-weight--normal);
      }
    }
  }
}

.sf-category-sidebar {
  &__header {
    padding: 0;
    margin: var(--spacer-xs) 0 0;
    font-family: var(--font-family--primary) !important;
    font-weight: var(--font-weight--bold);
    color: var(--c-primary);
  }
}

.carousel-category {
  &__item {
    width: auto !important;
  }

  ::v-deep .sf-carousel__slides:first-child {
    margin-left: var(--pictime-spacer-base);
  }
}

.menu {
  &__item {
    font-family: var(--font-family--primary) !important;
    font-size: var(--cromo-font-size--body);
    font-weight: var(--font-weight--normal);
    background-color: var(--c-background);
    padding: 9px var(--spacer-lg) 10px var(--spacer-lg);
    border-radius: 100px;
    height: 38px;

    ::v-deep .sf-menu-item__mobile-nav-icon {
      display: none;
    }

    //fix bug on ios for buttons to navigate in categories (surcharge sfbutton)
    &.sf-menu-item.sf-button:before {
      width: unset;
    }
  }
}
</style>
