const truncateString = (text, maxLength, suffix) => {
  if (!text) {
    return '';
  }
  if (text.length > maxLength) {
    return text.substr(0, text.lastIndexOf(' ', maxLength)) + suffix;
  } else {
    return text;
  }
}

export default truncateString;
