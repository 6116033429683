<template>
  <li class="sf-carousel-item glide__slide">
    <slot/>
  </li>
</template>
<script>
export default {
  name: "PictimeCarouselItem",
};
</script>
