<template>
  <div class="sf-carousel">
    <div class="sf-carousel__wrapper">
      <div ref="glide" class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides sf-carousel__slides">
            <slot/>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import PictimeCarouselItem from "./_internal/PictimeCarouselItem.vue";
import Glide from "../../helpers/utils/lib/glide";

Vue.component("PictimeCarouselItem", PictimeCarouselItem);
export default {
  name: "PictimeCarousel",
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      glide: null,
      defaultSettings: {
        type: "slider",
        rewind: false,
        perView: 2,
        gap: 10,
      },
    };
  },
  computed: {
    mergedOptions() {
      let breakpoints = { ...this.defaultSettings.breakpoints };
      if (this.settings.breakpoints) {
        breakpoints = { ...breakpoints, ...this.settings.breakpoints };
      }
      return {
        ...this.defaultSettings,
        ...this.settings,
        breakpoints: breakpoints,
      };
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      if (!this.$slots.default) return;
      const glide = new Glide(this.$refs.glide, this.mergedOptions);
      const size  = this.$slots.default.filter((slot) => slot.tag).length;
      if (size <= glide.settings.perView) {
        glide.settings.perView = size;
        glide.settings.rewind  = false;
      }
      glide.mount();
      glide.on("run.before", (move) => {
        const { slidePerPage, rewind, type } = this.mergedOptions;
        if (!slidePerPage) return;
        const { perView } = glide.settings;
        if (!perView > 1) return;
        const { direction } = move;
        let page, newIndex;
        switch (direction) {
          case ">":
          case "<":
            page     = Math.ceil(glide.index / perView);
            newIndex =
              page * perView + (direction === ">" ? perView : -perView);
            if (newIndex >= size) {
              if (type === "slider" && !rewind) {
                newIndex = glide.index;
              } else {
                newIndex = 0;
              }
            } else if (newIndex < 0 || newIndex + perView > size) {
              if (type === "slider" && !rewind) {
                newIndex = glide.index;
              } else {
                newIndex = size - perView;
              }
            }
            move.direction = "=";
            move.steps     = newIndex;
        }
      });
      this.glide = glide;
    });
  },
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/organisms/SfCarousel.scss";
</style>
