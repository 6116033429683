























































































































































































































































import LazyHydrate from 'vue-lazy-hydration';
import { SfButton, SfHeading, SfLink, SfLoader, SfModal, SfPagination, SfSelect } from '@storefront-ui/vue';
import { defineComponent, onUpdated, ref, ssrRef, useContext, useFetch } from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import CategoryPagination from '~/modules/catalog/category/components/pagination/CategoryPagination.vue';
import { useFacet, useUiHelpers, useUiState, } from '~/composables';
import type { MetaInfo } from 'vue-meta';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { useUrlResolver } from '~/composables/useUrlResolver';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import facetGetters from '~/modules/catalog/category/getters/facetGetters';
import { useProductConditions } from '~/composables/useProductConditions';
import PictimeNavbar from '~/modules/catalog/category/components/navbar/PictimeNavbar.vue';
import CategoryBreadcrumbs from '~/modules/catalog/category/components/breadcrumbs/CategoryBreadcrumbs.vue';
import { isCategoryTreeRoute } from '~/modules/GraphQL/CategoryTreeRouteTypeguard';
import PictimeCategoryTop from '~/modules/catalog/category/components/PictimeCategoryTop.vue';
import CategorySidebarMenu from '~/modules/catalog/category/components/sidebar/CategorySidebarMenu';
import SvgImage from '~/components/General/SvgImage.vue';
import HTMLContent from '~/components/HTMLContent.vue';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import type { SortingModel } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import type { Pagination } from '~/composables/types';
import { chunk, merge } from 'lodash-es';
import { ProductConditionQuery } from '~/custom-api/api/productConditions/types';
import ProductsCarousel from '~/modules/catalog/product/components/ProductsCarousel.vue';

export default defineComponent({
  name: 'CategoryPage',
  components: {
    HTMLContent,
    CategoryPagination,
    CategoryEmptyResults: () => import('~/modules/catalog/category/components/CategoryEmptyResults.vue'),
    PictimeFilters: () => import('~/modules/catalog/category/components/filters/PictimeFilters.vue'),
    CmsContent: () => import('~/modules/catalog/category/components/cms/CmsContent.vue'),
    PictimeCategoryProductList: () => import('~/modules/catalog/category/components/views/PictimeCategoryProductList.vue'),
    PictimeNavbar,
    CategoryBreadcrumbs,
    SfSelect,
    SfButton,
    SfPagination,
    LazyHydrate,
    SfHeading,
    SkeletonLoader,
    PictimeCategoryTop,
    CategorySidebarMenu,
    SfLoader,
    SvgImage,
    SfLink,
    ProductsCarousel,
    SfModal
  },
  transition: 'fade',
  props: {
    activeFilters: {
      type: Number,
      default: 0,
    }
  },
  head(): MetaInfo {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });

    let title = this?.activeCategory?.name;
    let metaDescription = this.$t('category_meta_description');

    let myLinks = [];
    const meta = [];

    i18nHead.link.forEach(element => {
      if (element.rel !== 'canonical') {
        myLinks.push(element);
      }
    });

    // Page recherche et page catégorie
    const indexRobot = this.$root.context.$config.seoMetaRobot ? null : 'noindex, nofollow';

    myLinks.push({
      hid: 'canonical',
      rel: 'canonical',
      href: this.$root.context.$config.vsfStoreUrl + this.$route.path + '?page=' + this.pagination.currentPage
    })

    if (indexRobot) {
      meta.push({
        name: 'robots',
        content: indexRobot
      });
    }

    meta.push(
      { hid: 'description', name: 'description', content: metaDescription },
      { hid: 'og:title', property: 'og:title', content: title },
      { hid: 'og:description', property: 'og:description', content: metaDescription },
      { hid: 'og:site_name', property: 'og:site_name', content: this.$t('site_name') },
    );

    return {
      title,
      link: [
        ...myLinks
      ],
      meta
    }
  },

  data() {
    return {
      SelectSortBy: 'Sort: Default'
    }
  }
  ,
  setup(_, { emit }) {
    const uiHelpers = useUiHelpers();
    const doChangeSorting = (sort: string) => {
      uiHelpers.changeSorting(sort, false);
      onReloadProducts();
      emit('reloadProducts');
    };

    const { addTags } = useCache();
    const seoBloc1 = ref('');
    const seoBloc2 = ref('');
    const products = ssrRef<ProductInterface[]>([]);
    const currentProducts = ssrRef<ProductInterface[]>([]);
    const sortBy = ref<SortingModel>({ selected: '', options: [] });
    const pagination = ref<Pagination>({});
    const productsLoading = ref(true);
    const isPriceLoaded = ref(false);
    const priceLoading = ref(true);
    const isDataFetching = ref(false);

    const productContainerElement = ref<HTMLElement | null>(null);

    const priceStock = ref([]);

    const { search: resolveUrl } = useUrlResolver();
    const {
      toggleFilterSidebar,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
      isFilterSidebarOpen,
      toggleSortByCategory,
      isSortByCategoryOpen
    } = useUiState();
    const { result, search } = useFacet();
    const { addItemToCart } = useAddToCart();
    const { fetchProductConditions } = useProductConditions();

    const { app } = useContext();

    const { activeCategory, loadCategoryTree, isCategoryTreeLoaded } = useTraverseCategory();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();

    const isPromo = ref(false);
    const isSearch = ref(false);
    const activeCategoryName = ref('');

    const getIsPromo = (path) => {
      return path === '/promo';
    };

    const getIsSearch = (path) => {
      return path === '/search';
    };

    const getActiveCategoryName = () => {
      let res = '';
      if (!isPromo.value && !isSearch.value) {
        if (!activeCategory.value) {
          loadCategoryTree();
        }
        res = activeCategory.value?.name ?? '';
      } else if (isSearch.value) {
        res = uiHelpers.getFacetsFromURL().term;
      } else {
        res = 'Special Offers';
      }

      return res;
    };

    const routeData = ref<CategoryTree | null>(null);

    const itemsPerPage = ref(12);
    const page = ref(1);

    const getSpecialCategory = (categoryList, type) => {
      let res = null;
      Object.keys(categoryList).forEach((category) => {
        if (categoryList[category]?.category_type === type) {
          res = categoryList[category];
        }
      });
      if (res == null) {
        res = categoryList[0];
      }
      return res.uid;
    };

    const categoryUid = ref('');
    const type = ref(0);
    const filters = ref({});

    const fetchData = async () => {
      isDataFetching.value = true;

      const resolvedUrl = await resolveUrl();

      if (!isPromo.value && !isSearch.value) {
        filters.value = {};
        if (!activeCategory.value) {
          await loadCategoryTree();
        }

        if (isCategoryTreeRoute(resolvedUrl)) {
          routeData.value = resolvedUrl;
        }

        if (!categoryUid.value) {
          categoryUid.value = routeData.value?.uid;
        }
      } else {
        type.value = isPromo.value ? 4 : 3;
        filters.value = {};

        await categoriesListLoad({ pageSize: 10, customQuery: { categoryList: 'customCategoryList' } });
        categoryUid.value = getSpecialCategory(categoryList.value, type.value);
      }

      isPromo.value = getIsPromo(resolvedUrl.relative_url);
      isSearch.value = getIsSearch(resolvedUrl.relative_url);
      activeCategoryName.value = getActiveCategoryName();

      if (activeCategory.value) {
        categoryUid.value = activeCategory.value.uid;
      }

      let finalFilters = {
        ...filters.value,
        ...uiHelpers.getFacetsFromURL().filters,
      };

      await Promise.all([ //Get products
        search({
          ...uiHelpers.getFacetsFromURL(),
          category_uid: categoryUid.value,
          itemsPerPage: itemsPerPage.value,
          page: page.value,
          filters: finalFilters
        }),
      ]);

      products.value = facetGetters.getProducts(result.value) ?? [];
      productsLoading.value = false;
      sortBy.value = facetGetters.getSortOptions(result.value);
      pagination.value = facetGetters.getPagination(result.value);

      products.value = products.value.map(p => {
        return merge({}, { priceLoading: true }, p);
      });

      const token = app.$cookies.get('shop_token_id', { parseJSON: false });
      const tokenId = token ? token : '';
      await callProductConditions(tokenId);

      isDataFetching.value = false;
    };

    const goToPage = (pageNum: number) => {
      page.value = pageNum;
      uiHelpers.changePage(pageNum, false);
      fetchData();
    };

    const onReloadProducts = () => {
      goToPage(1);
    };

    const metaDescription = activeCategory.value?.meta_description;

    const loadMoreProducts = async () => {
      let currentPage = page.value;

      if (currentProducts.value.length == 0) {
        await searchCategoryProduct();
        currentProducts.value = facetGetters.getProducts(result.value);
      }

      let box = document.querySelector('.main.section');
      let height = box.offsetHeight;

      box.style.height = height + 'px'; //Keep height on main section to avoid page to blink / move when reloading products

      pushNewSate('Next', 'Next page', 'page', (currentPage + 1)); //Keep URL relevant

      page.value = currentPage + 1;
      await searchCategoryProduct(); //Call to API

      box.style.height = 'auto';
      let newProducts = facetGetters.getProducts(result.value) //Register the new products

      newProducts.forEach(p => currentProducts.value.push(p));

      products.value.splice(0, products.value.length); //Empty products
      currentProducts.value.forEach(p => products.value.push(p)); //Reload products list on page

      const token = app.$cookies.get('shop_token_id', { parseJSON: false });
      const tokenId = token ? token : '';

      await callProductConditions(tokenId);

      pagination.value = facetGetters.getPagination(result.value);
    };

    const callProductConditions = async (tokenId) => {
      let productsChunks = chunk(products.value, 6);
      for (const prods of productsChunks) {
        prods.forEach(prod => {
          prod.priceLoading = true;
        });

        let skus = prods.map(p => ({ sku: p.sku }));

        let priceStocks = await fetchProductConditions({
          tokenId: tokenId,
          products: skus
        });

        //Merge les données produits et les données priceStock pour tout avoir dans le produit
        products.value = products.value.map(product => {
          let priceItem: ProductConditionQuery = priceStocks.find(price => price.sku === product.sku);

          if (typeof priceItem !== 'undefined' && priceItem.sku === product.sku) {
            product.priceLoading = false;
          }

          if (priceStocks.length == 0) {
            product.priceLoading = false;
          }

          return merge({}, priceItem, product);
        });
      }
    };

    const getAllUrlParams = () => {
      let urlQuery = typeof window !== 'undefined' ? window.location.search : '';
      let urlParams = new URLSearchParams(urlQuery);

      return urlParams;
    };

    const pushNewSate = (stateName, stateDescription, stateParameterName, stateParameterValue) => {
      let params = getAllUrlParams();
      let entries = params.entries();
      let stateOtherParams = '';

      for (const entry of entries) {
        if (entry[0] && entry[1] && entry[0] !== stateParameterName) {
          stateOtherParams = stateOtherParams + '&' + entry[0] + '=' + entry[1];
        }
      }

      window.history.pushState(stateName, stateDescription, '?' + stateParameterName + '=' + stateParameterValue + stateOtherParams);
    };

    const searchCategoryProduct = async () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      filters.value = {};
      if (isPromo.value) {
        type.value = 4;
        filters.value = {};
      }

      let finalFilters = {
        ...filters.value,
        ...uiHelpers.getFacetsFromURL().filters,
      };

      await search({
        ...uiHelpers.getFacetsFromURL(),
        category_uid: categoryUid.value,
        itemsPerPage: itemsPerPage.value,
        page: page.value,
        filters: finalFilters
      });
    };

    const trunckateTxt = function (blocSeo, btnReadMore) {
      if (blocSeo === null || blocSeo === undefined) {
        return;
      }

      let eltHeight = blocSeo.clientHeight;

      /* Calcule le nombre de ligne en fonction du font-size et du line-height */
      const eltFontSize = parseFloat(window.getComputedStyle(blocSeo, null).getPropertyValue('font-size'));
      let eltLineHeight = parseFloat(window.getComputedStyle(blocSeo, null).getPropertyValue('line-height'));

      if (isNaN(eltLineHeight)) {
        eltLineHeight = eltFontSize * 1.25;
      }

      const numberLine = Math.ceil(eltHeight / eltLineHeight);

      if (numberLine > 2) {
        blocSeo.classList.add('trunckated');
        btnReadMore.classList.remove('hidden');
      }

      btnReadMore.addEventListener('click', event => {
        blocSeo.classList.remove('trunckated');
        btnReadMore.classList.add('hidden');
      });
    };

    const setTags = async () => {
      products.value = facetGetters.getProducts(result.value) ?? [];

      const tags = [{ prefix: CacheTagPrefix.View, value: 'category' }];
      const productTags = products.value.map((product) => ({
        prefix: CacheTagPrefix.Product,
        value: product.uid,
      }));
      addTags([...tags, ...productTags]);
    };

    useFetch(async () => {
      await fetchData();
    });

    onSSR(async () => {
      await fetchData();
      await setTags();
    });

    onUpdated(async () => {
      const blocSeo1 = document.querySelector('.seo-block-1 p');
      const btnReadMore1 = document.getElementById('read-button-1');
      const blocSeo2 = document.querySelector('.seo-block-2 p');
      const btnReadMore2 = document.getElementById('read-button-2');

      trunckateTxt(blocSeo1, btnReadMore1);
      trunckateTxt(blocSeo2, btnReadMore2);
    });

    return {
      isPriceLoaded,
      ...uiHelpers,
      toggleFilterSidebar,
      isCategoryGridView,
      changeToCategoryListView,
      changeToCategoryGridView,
      isFilterSidebarOpen,
      addItemToCart,
      pagination,
      products,
      priceStock,
      sortBy,
      seoBloc1,
      seoBloc2,
      activeCategoryName,
      routeData,
      productContainerElement,
      onReloadProducts,
      metaDescription,
      isPromo,
      isSearch,
      doChangeSorting,
      activeCategory,
      isCategoryTreeLoaded,
      loadMoreProducts,
      productsLoading,
      priceLoading,
      isDataFetching,
      categoryUid,
      toggleSortByCategory,
      isSortByCategoryOpen
    };
  },
});
